import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect } from "react";
import WOW from "wow.js";
import Blog from "./pages/Blog/Blog";
import BlogDetailsPage from "./pages/BlogDetails/BlogDetailsPage";
import BlogDetailsPageTwo from "./pages/BlogDetails/BlogDetailsPageTwo";
import BlogDetailsPageThree from "./pages/BlogDetails/BlogDetailsPageThree";
import BlogDetailsPageFour from "./pages/BlogDetails/BlogDetailsPageFour";
import { useLocation } from "react-router-dom";

function App() {
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="blog" element={<Blog />} />
        <Route path="vminer-details" element={<BlogDetailsPage />} />

        <Route path="Privacy-Policy" element={<BlogDetailsPageTwo />} />
        <Route path="terms-conditions" element={<BlogDetailsPageThree />} />
        <Route path="disclaimer" element={<BlogDetailsPageFour />} />
      </Routes>
    </>
  );
}

export default App;
