import React from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../lib/helpers";

const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="footer-content">
                    <p></p>
                    <ul className="footer-social">
                      <li>
                        <a href="https://twitter.com/eco_bitcoin_" target="_blank" rel="noreferrer">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://t.me/FREDEnergycommunity" target="_blank" rel="noreferrer">
                          <i className="fab fa-telegram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <h4 className="fw-title">Useful Links</h4>
                  <div className="footer-link">
                    <ul>
                    <li>
                      <Link to="/privacy-policy">Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions">Terms and conditions</Link>
                    </li>
                      <li>
                        <Link to="/vminer-details">Available Virtual Miners</Link>
                      </li>
                      <li>
                        <Link to="/disclaimer">Disclaimer</Link>
                      </li>
                      <li>
                        <Link to="https://fredenergy.org" target="blank" >FRED Energy Website</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">

              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="copyright-text">
                  <p>Copyright &copy; 2024. All Rights Reserved FRED Energy Ltd</p>
                </div>
              </div>
              <div className="col-lg-6 d-none d-sm-block">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="/privacy-policy">Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-conditions">Terms and conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
