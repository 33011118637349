import React, { useEffect } from 'react';
import AssetInfo from '../Stats/AssetInfo';

const Popup1 = ({ closePopup }) => {
  // Use useEffect to add an event listener to the document
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside of the .popup-inner
      if (!document.querySelector('.popup-inner').contains(event.target)) {
        closePopup();
      }
    };

    // Add the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts or re-renders
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closePopup]); // Ensure closePopup is stable or wrapped in useCallback if defined in a parent component

  return (
    <div className="popup">
      <div className="popup-inner">
        <AssetInfo />
        <button onClick={closePopup}>Close</button>
      </div>
    </div>
  );
};

export default Popup1;
