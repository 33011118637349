import React from "react";
import blogImg from "../../assets/img/blog/virtual-miners-ebtc.jpg";


const BlogDetails = () => {
  return (
    <div className="blog-post-item vminer-details-wrap">
      <div className="blog-post-thumb">
        <img src={blogImg} alt="" />
      </div>
      <div className="blog-post-content">
           <h2 className="title">How to get an eBTC Virtual Miner
        </h2>
        <p>Virtual Miner NFTs are minted on the Stellar Network and listed on Litemint
        </p>
        <p>In the near future we will be adding wallet connect to the website. This will enable users to view stats about thier Virtual Miners. 
          It is recommended to use a new Stellar based wallet if you feel uncomfortable connecting to websites with your main Stellar account.</p>
        <p>For those who prefer using desktop wallets, Rabet is a great browser extension and has a Metamask feel. 
          You can create a new wallet or import an existing wallet.
          For mobile, Lobstr is a great choice.</p>
        <p>Once your wallet is setup and funded, you can head over to Litemint and place a bid for one of the available Virtual Miners of your choice. 
                  The higher the speed indicates the greater number of eBTC you will mine per hour.</p>
                <p>Once you have sucessfully bid/purchased the Virtual Miner NFT, you will begin to receive eBTC every hour.
            Please add the trustline to eBTC or claim the eBTC claimable balance that will otherwise be sent to enable the trustline automatically.</p><br></br>
            <p>ASSET Issuer: GCEAHWRSUTM2T6ATHI7DFGLXV4COMFL4OGJLAELLJIE6S6AWBGUEEBTC<br></br>
            ASSET Code: eBTC<br></br>
            ASSET Domain: ebtc.fredenergy.org<br></br>
            Note: It may take upto 2 hours for the first mined eBTC to be sent to your wallet.
          </p>
          <p>Below is an updated list of Virtual Miners currently available</p>
      </div>
    </div>
    
  );
};

export default BlogDetails;
