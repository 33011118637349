import React from 'react';

const AssetInfo2 = () => {
    // Information from the image
    const pool1 = 'USDC/eBTC';
    const pool2 = 'XLM/eBTC';
    const pool3 = 'yBTC/eBTC';
    const pool4 = 'GOLD/eBTC';
    const pool5 = 'SILVER/eBTC';

    return (
      <div className='container'>
        <div className="row">
          <div className="col-lg-12">
            <div className="asset-contents">
<p>LIQUIDITY POOL:&nbsp;&nbsp;{pool1} <a href="https://stellar.expert/explorer/public/liquidity-pool/f7fc2d4ff9c9b313c052e17dfbb988dc5fef745c6c7aacc3e5b566b2bd6f32e3" target="_blank" rel="noopener noreferrer">
                View on Explorer
</a></p>
<p>LIQUIDITY POOL:&nbsp;&nbsp;{pool2} <a href="https://stellar.expert/explorer/public/liquidity-pool/9388a1e723b826996919f743017e2c5a8f72f93c3b336fe8bf8f9835526ba7c8" target="_blank" rel="noopener noreferrer">
                View on Explorer
</a></p>
<p>LIQUIDITY POOL:&nbsp;&nbsp;{pool3} <a href="https://stellar.expert/explorer/public/liquidity-pool/6fd7000f078e186cb0b50ccd77704a81becff2950c0d75ab52cdbc2d0cba1d24" target="_blank" rel="noopener noreferrer">
                View on Explorer
</a></p>
<p>LIQUIDITY POOL:&nbsp;&nbsp;{pool4} <a href="https://stellar.expert/explorer/public/liquidity-pool/88e4ac484ec6c6409538fe07342f201bcb95cff3cb8e0bd15270a84f287fab01" target="_blank" rel="noopener noreferrer">
                View on Explorer
</a></p>
<p>LIQUIDITY POOL:&nbsp;&nbsp;{pool5} <a href="https://stellar.expert/explorer/public/liquidity-pool/ac71f6e1e5143fdb8194525956bf8fac8e49b392ff440fd9f79f39cfdde018db" target="_blank" rel="noopener noreferrer">
                View on Explorer
</a></p>
            </div>
          </div>
        </div>
      </div>
    );
  
};

export default AssetInfo2;
