import React from "react";
import Banner from "../../components/Banner/Banner";
import Roadmap from "../../components/Roadmap/Roadmap";
import Liquidity from "../../components/Liquidity/Liquidity";
import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import LayoutOne from "../../layouts/LayoutOne";
import HowItWorks from "../../components/HowItWorks/HowItWorks";

const Home = () => {
  return (
    <LayoutOne>
      <main className="fix">
        <Banner />
        <WhoWeAre />
        <Liquidity />
        <HowItWorks />
        <Roadmap />
        <WhyChooseUs />
       

        <div className="area-bg">

          <WhitePaper />
        </div>
      </main>
    </LayoutOne>
  );
};

export default Home;
