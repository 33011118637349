import React from "react";

const TermsConditions = () => {
  return (
    <div className="blog-post-item vminer-details-wrap">
      <div className="blog-post-thumb">
      </div>
      <div className="blog-post-content">
           <h2 className="title">How to get an eBTC Virtual Miner</h2>
           <h4>NFT Ownership &amp; Licence Agreement</h4> <p>The product known as a Virtual Miner (hereinafter referred to as the &quot;Product&quot;) is a digital artwork collection project executed on the Stellar network, provided by FRED Energy Ltd, a company based in the United Kingdom (hereinafter referred to as the &quot;Company&quot;).</p>

<p>The website of the Product, along with other associated software, services, media channels, external websites, or applications connected to it, are provided to users under the following Terms of Service (&lsquo;Terms&rsquo;). Your use of the website, purchase or sale of the Product, intermediation, and all actions related to the Product, including but not limited to associated content, related businesses, brand extensions, creation and sale of derivative works and distribution, automatically subjects you to the legal binding of these Terms, so please read them carefully.</p>

<h4>Definitions</h4> <p>1. 'Product': A digital artwork collection of non-fungible tokens (NFTs) on the Stellar network, serving as Proof of Participation (POP) for receiving eBTC rewards <br></br>2. 'eBTC': a Stellar-based utility token without inherent value.<br></br>3. 'Digital Assets': The Company&rsquo;s NFT products and eBTC, encompassing digital content such as videos and images.<br></br> 4. 'NFT': A unique token on a blockchain network, signifying ownership of digital items.<br></br> 5. 'NFT Marketplace': An online platform for trading NFTs.<br></br> 6. 'Wallet': Software for storing, managing, and transferring NFTs. <br></br>7. 'User': An individual who engages with the Company&rsquo;s products or services and accepts these Terms. <br></br>8. 'Holder': An individual owning a Company NFT or eBTC verified by blockchain transactions.</p>

<h4>Important Notice:</h4><p><strong>Virtual Miner Product Usage:</strong> The Product is purposed solely as Proof of Participation (POP) in a Virtual Mining experiment. It is not to be used for payment or settlement. Not an investment, it is essentially a stock image or digital content for experimental use. Users acknowledge that no profit distribution will occur through this project. Acceptance of these Terms confirms user understanding and agreement that the Product and any associated Digital Assets are not recognized as &quot;virtual assets.&quot;</p>

<h4> Terms of Service Agreement </h4><p>By accessing the Company's website, or using its products and services, users are bound by these Terms. Disagreement with these Terms necessitates abstention from using the Company's offerings. The Company may offer NFTs and physical items (&quot;Company Products&quot;) for sale on its own or third-party platforms. Only individuals 18 and older may use the Company&rsquo;s websites; usage by those under 18 is prohibited. Additional specific terms, if any, must be agreed upon to transact in Company Products. Transactions made through third-party partners are governed by those partners' terms, and the Company disclaims liability for any claims or damages from such transactions. The Company is not responsible for irretrievable payments or transactions made via third-party services. Company Products may also be sold through Auctions, subject to the Company's specific Auction rules. Revised Content Ownership Statement for Clarity and Conciseness:</p>

<p>The Company exclusively owns its website, encompassing all underlying code, databases, functionality, designs, audio, video, text, graphics (collectively, &quot;Content&quot;), trademarks, and logos. This Content is protected by copyright, trademark laws, and other intellectual property rights, and is either owned, controlled, or licensed to the Company.</p>

<h4> Ownership Clause</h4><p>The Product, issued as a non-fungible token (NFT) on the Stellar blockchain, grants full ownership to the user upon purchase via the Company&rsquo;s website, through independent or affiliated contracts, or on NFT Marketplaces. This ownership is inviolable; the Company cannot confiscate, limit, or alter the user's ownership rights under any circumstances.</p>

<p> Digital Content and Trading Digital content acquired from the Company or through third-party agreements can be traded on external marketplaces, subject to those marketplaces' or the Company&rsquo;s terms. The Company is not involved in and is not responsible for such secondary trading or the operation of these external sites. Users bear full responsibility for the use of these sites and any related disputes or claims.</p>

<h4>Tax Responsibility</h4><p> Users are responsible for all taxes incurred from transactions involving the Company&rsquo;s digital content, including income, sales, and other governmental levies.</p>

<h4>Commercial Use License</h4><p> Users who hold digital content or NFTs are granted a commercial license by the Company.</p>

<h4>Risk Assumption Statement</h4>

<p>1. **Market Volatility**: Users acknowledge the high volatility inherent in NFT and digital content markets, which may impact the value of the Company's products. The products should not be seen as financial investments, securities, or derivatives as defined by financial investment services and capital market laws.</p>

<p>2. **Ownership and Advice**: Acquiring a product grants ownership of related NFTs. Information from the Company's website or referenced documents is not investment advice or an invitation for contracts. The website content is not an offer or invitation to buy stocks, securities, or financial products. The artistic products are neither regulated by any authority nor recognized as &quot;virtual assets&quot; by FATF standards or under the EU's proposed MiCA.</p>

<p>3. **Internet-based Currency Risks**: Users bear all risks related to using digital assets, such as technology failures or cybersecurity threats. The Company is not liable for damages from malicious websites. Users must navigate external sites with caution.</p>

<p>4. **Regulatory Uncertainty**: Users accept the risk of adverse impacts from evolving regulations on NFTs and blockchain technology.</p>

<p>5. **Network Interruptions**: Users accept risks related to operational disruptions or issues on the Stellar network that may affect the product.</p>

<p>6. **Blockchain Transactions**: Users are responsible for the security and management of their Stellar-based wallets and must verify transaction authenticity on the Company's website. Transactions on the blockchain are final and cannot be reversed or altered.</p>

<h4>Risk Assumption and Disclaimers</h4><p> The Company provides its website &quot;as is&quot; without any guarantees. It does not promise uninterrupted service or immunity from errors, viruses, or security breaches. Using the website is solely at the user's risk.</p>

<h4>Product and Service Warranties</h4><p>The Company makes no warranty regarding its products and services. All warranties are expressly disclaimed to the full extent permitted by law. The Company is not liable for damages related to the use of its products or services, including but not limited to direct, indirect, incidental, or consequential damages.</p>

<h4>Blockchain and Technology-Related Disclaimers</h4><p> The Company is not liable for issues arising from blockchain technology, digital wallets, NFT transfers, or market disruptions.</p>

<h4>User Acknowledgments</h4><p> Users accept the risks associated with using blockchain technology, including potential loss, security threats, and market uncertainties. The Company bears no responsibility for these risks.</p>

<h4>International Law Compliance</h4><p> The Company's products or services are not considered virtual assets under UK or other countries' laws. Any disputes arising from this are not the Company's responsibility.</p>

<h4>Service Availability</h4><p> The Company does not promise uninterrupted availability of its products or services and is not responsible for service disruptions.</p>

<h4>Third-Party Interactions</h4><p> No guarantees are made regarding interactions with third parties.</p>

<h4>Payment and Investment</h4><p> The Company&rsquo;s products or services are not to be used as payment methods, securities, or investment opportunities. Any such use is rejected, and the Company disclaims responsibility.</p>

<h4>Technical and Regulatory Risks</h4><p> The Company is not liable for losses due to hacking, security vulnerabilities, or changes in blockchain technology and regulations.</p>

<h4>Technological Knowledge Representation</h4><p> You assert that you are proficient in the use of blockchain and cryptocurrency technologies. You recognize and accept the inherent risks of technological and cryptographic systems, including but not limited to blockchains, NFTs, cryptocurrencies, smart contracts, consensus algorithms, and decentralized networks. These risks may encompass system malfunctions, security breaches, and various technological failures that could lead to loss or diminished opportunities in the digital asset space.</p>

<h4>Acknowledgment of Risks</h4><p> You acknowledge the high volatility and unpredictability of blockchain asset prices, influenced by various external factors. You accept that digital assets, including the Artwork, may have minimal or no intrinsic value. Market fluctuations could significantly and adversely affect the value of the Artwork, with no guarantee of retaining its original purchase value or gaining future value.</p>

<h4>Terms of Service Amendments</h4><p> 1. The Company may revise these Terms at any time. Updates will be posted on our website; please review them regularly. No separate notification is necessary, as the website does not collect personal data. 2. Changes to these Terms will be announced at least 8 days prior to their implementation, including details, effective date, and rationale. Amendments affecting significant user rights or duties will be announced 30 days in advance. 3. By continuing to use the website post-update, you agree to the revised Terms. Disagreement with changes requires cessation of website use.</p>

<h4>Jurisdiction and Governing Law</h4><p> The laws of the United Kingdom govern these Terms and the use of the Company&rsquo;s services and products. These laws apply irrespective of your residence or where you access our website. Disputes will be resolved under the exclusive jurisdiction of the UK courts.</p>

<h4>Miscellaneous</h4><p> These Terms and related website policies are the complete agreement between you and the Company. Oral amendments are ineffective. By using our services and website, you accept these Terms. If a Term is voided by law, the rest remain valid. The enforceability of these Terms is unaffected in other respects by any invalid or unenforceable provision.</p>

      </div>
    </div>
    
  );
};

export default TermsConditions;
