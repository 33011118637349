import React, { useState } from "react";
import img01 from "../../assets/img/images/virtual-miner-ebtc-fx750.jpg";
import Popup from "../Pop/Popup";

const WhoWeAre = (props) => {
      // State to manage the visibility of the Popup component
      const [isPopupVisible, setPopupVisible] = useState(false);

      // Function to toggle the visibility of the Popup
      const togglePopup = () => {
        setPopupVisible(!isPopupVisible);
      };
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="eBTC virtual miner" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">About</span>
                <h2 className="title">
                  21,000,000 eBTC mined virtually using functional NFTs and coded algorithms
                </h2>
              </div>
              <p>
              Eco-Bitcoin: Revolutionizing Currency with Virtual Mining - Green, Sustainable, and Powering the Future of Finance without Harming the Planet
              </p>
              <button className="btn" onClick={togglePopup}>
              Join the experiment and Get a Virtual Miner
      </button>
            </div>
          </div>
        </div>
      </div>
                  {/* Conditionally render the Popup component based on isPopupVisible state */}
                  {isPopupVisible && <Popup closePopup={togglePopup} />}
    </section>
  );
};

export default WhoWeAre;
