import React from "react";
import LayoutBlogText from "../../layouts/LayoutBlogText";
import Disclaimer from "../../components/Terms/Disclaimer";

const BlogDetailsPageFour = () => {
  return (
    <LayoutBlogText pageTitle={"Terms and Conditions"} item={"Policy DETAILS"}>
      <Disclaimer />

    </LayoutBlogText>
  );
};

export default BlogDetailsPageFour;
