import React from "react";


const Disclaimer = () => {
  return (
    <div className="blog-post-item vminer-details-wrap">
      <div className="blog-post-thumb">
      <h2>Disclaimer</h2>
<p>The information provided on this website does not constitute investment advice, financial advice, trading advice, or any other sort of advice, and you should not treat any of the website&rsquo;s content as such. We do not recommend that any cryptocurrency should be bought, sold, or held by you. Nothing on this website should be taken as an offer to buy, sell or hold a cryptocurrency.</p>
<p>FRED Energy make no recommendation as to the suitability of any of the tokens, products, services or transactions mentioned herein this website. This information shall not be considered as, and may not be used in connection with, an offer to sell or the solicitation of an offer to buy tokens or NFTs in any jurisdiction in which such offer or solicitation is unlawful.</p>
<p>Purchasing, holding, trading and using virtual currencies involve a high level of risk. We do recommend that you conduct your own due diligence and consult your financial advisor before making any investment decision. We do not accept any responsibility and will not be liable for the investment decisions you make based on the information provided on this website and information posted to our social media accounts. Please see our full&nbsp;Terms and Conditions</p>


      </div>
    </div>
    
  );
};

export default Disclaimer;
