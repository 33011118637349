import React from "react";
import BlogDetails from "../../components/BlogDetails/BlogDetails";
import LayoutBlog from "../../layouts/LayoutBlog";

const BlogDetailsPage = () => {
  return (
    <LayoutBlog pageTitle={"Virtual Mining eBTC"} item={"BLOG DETAILS"}>
      <BlogDetails />

    </LayoutBlog>
  );
};

export default BlogDetailsPage;
