import React from "react";
import LayoutBlogText from "../../layouts/LayoutBlogText";
import TermsConditions from "../../components/Terms/TermsConditions";

const BlogDetailsPageThree = () => {
  return (
    <LayoutBlogText pageTitle={"Terms and Conditions"} item={"Policy DETAILS"}>
      <TermsConditions />

    </LayoutBlogText>
  );
};

export default BlogDetailsPageThree;
