import React from "react";
import CountDownOne from "../CountDown/CountDownOne";

const Banner = (props) => {
  return (
    <section className="banner-area banner-bg">
      <div className="banner-shape-wrap">
         {/*<img src={shape01} alt="" className="img-one" />*/}
        {/*<img src={shape02} alt="" className="img-two" />*/}
        {/*<img src={shape03} alt="" className="img-three" />*/}
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              <h2 className="title">
                Eco Bitcoin - Virtual Mining
              </h2>
            </div>

          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="banner-countdown-wrap text-center">
              <h2 className="title">Next VMiner release in..</h2>

              <CountDownOne />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
