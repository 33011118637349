import React, { useState, useEffect } from 'react';

const AssetContents = () => {
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    // Adjust the path to match where you've placed your JSON file
    const fetchData = async () => {
        try {
          const response = await fetch('/output.json');
          if (!response.ok) {
            // Handle non-200 responses, possibly setting an error state to inform the user
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setAssets(data);
        } catch (error) {
          console.error("Failed to load asset data", error);
        }
      };
      

    fetchData();
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className='container'>
      <div className="row">
        <div className="col-lg-12"><h3>Updated every 10 minutes</h3>
   <div className="asset-contents">
      {assets.map((asset) => (
        <div key={asset.asset_code}>
          <p>Asset Code: {asset.asset_code}
          __ Virtual Miner: <a href={asset.url} target="_blank" rel="noopener noreferrer">available - visit marketplace</a></p>
        </div>
      ))}
    </div>
    </div>
    </div>
    </div>
  );
  
};

export default AssetContents;
