import React, { useState } from "react";
// Assuming you have a video file in the same location as your image
import videoSrc from "../../assets/img/videos/virtual-miner-ebtc-GFX500.mp4";
import Popup from "../Pop/Popup";

const HowItWorks = (props) => {
    // State to manage the visibility of the Popup component
    const [isPopupVisible, setPopupVisible] = useState(false);

    // Function to toggle the visibility of the Popup
    const togglePopup = () => {
      setPopupVisible(!isPopupVisible);
    };
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              {/* Updated video tag with inline styling for size adjustment */}
              <video autoPlay loop muted playsInline style={{ width: '100%', height: 'auto', maxWidth: '486px' }}>
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">How it works</span>
              </div>
              <p>
                Virtual Miners use what we call POP (Proof Of Participation) to mine eBTC<br></br>
                Each Virtual Miner has a set speed, which is its power ratio<br></br>
                The power ratio is used to calculate the mined rewards of eBTC per hour<br></br>
                FX100 - 1x speed<br></br>
                FX250 - 2.5 x speed<br></br>
                GFX500 - 3.6 x speed<br></br>
                GFX750 - 6 x speed<br></br>
                GFX808 - 7.8 x speed<br></br>
                Mined eBTC is paid hourly and weekly as follows;<br></br>
                50% of mined eBTC are paid hourly, the remaining 50% are accumulated and paid weekly as a claimable balance with a random claimable date between 9-12 months

              </p>
              <button className="btn" onClick={togglePopup}>
        Get a Virtual Miner
      </button>
            </div>
          </div>
        </div>
      </div>
            {/* Conditionally render the Popup component based on isPopupVisible state */}
            {isPopupVisible && <Popup closePopup={togglePopup} />}
    </section>
  );
};

export default HowItWorks;
