import React from "react";
import BlogListItem from "./BlogListItem";
import img01 from "../../assets/img/blog/blog_img01.jpg";
import img02 from "../../assets/img/blog/blog_img02.jpg";
import img03 from "../../assets/img/blog/blog_img03.jpg";

const BlogList = () => {
  const blog_list = [
    {
      url: "/vminer-details",
      src: img01,
      author: "",
      time: "Dec 28, 2022",
      comments: "03",
      title:
        "",
      summary:
        "",
    },
    {
      url: "/vminer-details",
      src: img02,
      author: "",
      time: "Dec 28, 2022",
      comments: "03",
      title: (
        <>

        </>
      ),
      summary:
        "of science",
    },
    {
      url: "/vminer-details",
      src: img03,
      author: "",
      time: "Dec 28, 2022",
      comments: "03",
      title: ``,
      summary:
        "",
    },
  ];

  return (
    <>
      {blog_list.map((blog, index) => (
        <BlogListItem key={index} item={blog} />
      ))}
    </>
  );
};

export default BlogList;
