import React, { useState } from "react";
import Popup from "../Pop/Popup1";
import cn from "classnames";

const SalesTabContent1 = (props) => {
      // State to manage the visibility of the Popup component
      const [isPopupVisible, setPopupVisible] = useState(false);

      // Function to toggle the visibility of the Popup
      const togglePopup = () => {
        setPopupVisible(!isPopupVisible);
      };
  return (
    <div
      className={cn("tab-pane fade", props.className)}
      id={props.id}
      role="tabpanel"
      aria-labelledby={props.ariaLabel}
    >
      <div className="chart-content-inner">
        <h2 className="title">{props.title}</h2>
        <p>{props.description}</p>
        <button className="btn" onClick={togglePopup}>
        Asset Information
      </button>
      </div>
                  {/* Conditionally render the Popup component based on isPopupVisible state */}
                  {isPopupVisible && <Popup closePopup={togglePopup} />}
    </div>
  );
};

export default SalesTabContent1;
