import React from "react";
import PageLoader from "../components/PageLoader/PageLoader";
import cn from "classnames";
import HeaderOne from "../components/Header/HeaderOne";
import FooterOne from "../components/Footer/FooterOne";



const LayoutBlog = (props) => {
  return (
    <div className={cn("")}>
      <PageLoader />

      <HeaderOne />

      <main>

        <section className="blog-area pt-130 pb-130">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">{props.children}</div>


            </div>
          </div>
        </section>
      </main>



      <FooterOne />
    </div>
  );
};

export default LayoutBlog;
