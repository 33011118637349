import React from "react";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Stage 1",
      title: "Concept",
      info: [
        "Virtual Miner Sales",
        "Liquidity Building",
      ],
    },
    {
      roadmapTitle: "Stage 2",
      title: "Marketing",
      info: [
        "Boost Awareness",
        "Target - all Virtual Miners to be sold",
      ],
    },

    {
      roadmapTitle: "Stage 3",
      title: "Soroban Contracts",
      info: [
        "Move logic into Soroban Contracts",
      ],
    },

    {
      roadmapTitle: "Stage 4",
      title: "Use Cases",
      info: [
        "Promote usage of eBTC",
        "Online payment apps",
      ],
    },



  ];


  return (
    <section id="roadmap" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">Project Outline</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
           {/* <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              ref={scrollRef}
              style={{ overflow: "auto" }}
  >*/}
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      
    </section>
  );
};

export default Roadmap;
