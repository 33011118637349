import React from "react";
import LayoutBlogText from "../../layouts/LayoutBlogText";
import PrivacyPolicy from "../../components/Terms/PrivacyPolicy";

const BlogDetailsPageTwo = () => {
  return (
    <LayoutBlogText pageTitle={"Privacy Policy"} item={"Policy DETAILS"}>
      <PrivacyPolicy />

    </LayoutBlogText>
  );
};

export default BlogDetailsPageTwo;
