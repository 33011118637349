import React from "react";
import { Link } from "react-router-dom";
import docImg from "../../assets/img/images/eBTC-whitepaper-front.jpg";

const WhitePaper = () => {
  return (
    <section className="document-area pt-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 order-2 order-lg-0">
            <div
              className="document-img text-center wow fadeInUp"
              data-wow-delay=".2s"
            >
              <img src={docImg} alt="" />
            </div>
          </div>

          <div className="col-lg-5 col-md-7">
            <div
              className="document-content mt-50 wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title mb-35">
                <span className="sub-title">Whitepaper</span>
              </div>

              <ul className="document-list">
                <li>White Paper</li>
              </ul>

              <a href="https://ebtc.fredenergy.org/2024-04-02-EcoBitcoin-Whitepaper.pdf" target="_blank" rel="noopener noreferrer" className="btn">
  Download Doc
</a>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhitePaper;
