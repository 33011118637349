import React from 'react';

const AssetInfo = () => {
    // Information from the image
    const assetCode = 'eBTC';
    const issuer = 'GCEAHWRSUTM2T6ATHI7DFGLXV4COMFL4OGJLAELLJIE6S6AWBGUEEBTC';
    const domain = 'ebtc.fredenergy.org';

    return (
      <div className='container'>
        <div className="row">
          <div className="col-lg-12">
            <div className="asset-contents">
                <p>ASSET CODE:&nbsp;&nbsp;{assetCode}</p>
                <p>ISSUER:&nbsp;&nbsp;{issuer}</p>
                <p>DOMAIN:&nbsp;&nbsp;{domain}</p>
                <a href="https://stellar.expert/explorer/public/asset/eBTC-GCEAHWRSUTM2T6ATHI7DFGLXV4COMFL4OGJLAELLJIE6S6AWBGUEEBTC-1" target="_blank" rel="noopener noreferrer">
                View on Explorer
</a>
<p></p>
<p>Wallets of the project:</p>
<p>Distributor: <a href="https://stellar.expert/explorer/public/account/GC54OYR3SJ33B6EJQVKLMHZLKMQPPCI2ORC6Z4ZBCZVVX4C3R4TTDIST" target="_blank" rel="noopener noreferrer">GC54OYR3SJ33B6EJQVKLMHZLKMQPPCI2ORC6Z4ZBCZVVX4C3R4TTDIST</a></p>
<p>Liquidity Pool: <a href="https://stellar.expert/explorer/public/account/GADLMUX24H7CETOCZLZYB6GGMO3IAGFISWGY5ITROQX42LZQ63YZELIQ" target="_blank" rel="noopener noreferrer">GADLMUX24H7CETOCZLZYB6GGMO3IAGFISWGY5ITROQX42LZQ63YZELIQ</a></p>
<p>Reward feeder account: <a href="https://stellar.expert/explorer/public/account/GBAQUKZJFM5QFBKWVNNRTYAS3IUAGJ26DTOJADKM7NRXWSR2X3ZECOIN" target="_blank" rel="noopener noreferrer">GBAQUKZJFM5QFBKWVNNRTYAS3IUAGJ26DTOJADKM7NRXWSR2X3ZECOIN</a></p>
<p>Rewards payment account: <a href="https://stellar.expert/explorer/public/account/GA3DEOEQTYMTNTIVZ7IIHJEJXFBX54HAETVQMH4YW2SMHIRBOZBECOIN" target="_blank" rel="noopener noreferrer">GA3DEOEQTYMTNTIVZ7IIHJEJXFBX54HAETVQMH4YW2SMHIRBOZBECOIN</a></p>

<p>GitHub info <a href="https://github.com/fredenrg/Eco-Bitcoin---eBTC" target="_blank" rel="noopener noreferrer">fredenrg/Eco-Bitcoin---eBTC</a></p>


            </div>
          </div>
        </div>
      </div>
    );
  
};

export default AssetInfo;
